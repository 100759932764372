import React, { useEffect } from 'react';
import { MainInfo } from '../../components/MainInfo/MainInfo';
import { AssetList } from '../MainPage/AssetList/AssetList';
import { useRecoilState } from 'recoil';
import { userState } from '../../states/userState';
import './Dashboard.scss';
import { chartConnectedState } from '../../states/chartConnectedState';

export const Dashboard = () => {
  const [profile, setProfile] = useRecoilState(userState);
  const setChartConnected = useRecoilState(chartConnectedState)[1];
  useEffect(() => {
    if (!document.getElementById('tradingview-widget-loading-script')) {
      const tvScriptLoadingPromise = new Promise((resolve) => {
        const script = document.createElement('script');
        script.id = 'tradingview-widget-loading-script';
        script.src = 'https://s3.tradingview.com/tv.js';
        script.type = 'text/javascript';
        script.onload = resolve;
        document.head.appendChild(script);
      });
      tvScriptLoadingPromise.then(() => setChartConnected(true));
    }
  }, [setChartConnected]);
  return (
    <div className='container'>
      <div>
        {profile ? (
          <>
            <MainInfo />
            <AssetList />
          </>
        ) : (
          // <HowItWorks />
          <h1 className='dashboard__title'>Log in first, please</h1>
          // <img className='main-page__image' src='/images/mock.png' />
        )}
      </div>
    </div>
  );
};
