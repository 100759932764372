import React, { useEffect, useState } from 'react';
import './BalanceProfit.scss';
import classNames from 'classnames';
import { useRecoilState, useRecoilValue } from 'recoil';
import { userState } from '../../states/userState';
import { positionsState } from '../../states/positionsState';

interface BalanceProfitProps {
  value: number;
  hasOpenedPositions: boolean;
}

const BalanceProfit = ({ value, hasOpenedPositions }: BalanceProfitProps) => {
  const [percentChange, setPercentChange] = useState<any>();
  const [profitChange, setProfitChange] = useState<any>();
  const [currentValue, setCurrentValue] = useState<any>();
  const user = useRecoilValue(userState);
  const [positions, setPositions] = useRecoilState(positionsState);
  const [totalPositions, setTotalPositions] = useState<any>(0);
  const getTotalPositions = () => {
    positions.forEach((position: any) => {
      setTotalPositions((totalPositions) => totalPositions + position.usdt);
    });
  };

  useEffect(() => {
    if (value) {
      getTotalPositions();
      const localAmount = hasOpenedPositions ? Number(value) + user.usdt : user.usdt;

      const amount = ((localAmount - 1000000 + totalPositions) / 1000000) * 100;

      setCurrentValue(localAmount.toFixed(6));
      setPercentChange(amount.toFixed(2));
      setProfitChange(localAmount + totalPositions - 1000000);
    }
  }, [value, hasOpenedPositions, user]);

  return (
    <div className='balance-wrapper'>
      <div className='balance-profit'>
        <div className='balance-profit__value'>{currentValue} USDT</div>
        {profitChange !== 0 && (
          <div className='balance-profit__change'>
            <div
              className={classNames('balance-profit__usdt', {
                'balance-profit__usdt_green': profitChange > 0,
                'balance-profit__usdt_red': profitChange < 0,
              })}
            >
              ${profitChange && Math.abs(profitChange.toFixed(2))}
            </div>
            <div className='balance-profit__divider' />
            <div
              className={classNames('balance-profit__percent', {
                'balance-profit__percent_green': profitChange > 0,
                'balance-profit__percent_red': profitChange < 0,
              })}
            >
              {percentChange && Math.abs(percentChange)}%
              <svg
                style={{
                  transform: percentChange > 0 ? 'rotate(0deg)' : 'rotate(180deg)',
                }}
                xmlns='http://www.w3.org/2000/svg'
                width='24'
                height='24'
                viewBox='0 0 24 24'
                fill='none'
              >
                <path
                  d='M6.09707 11.8428C6.2348 12.1028 6.49669 12.2649 6.78153 12.2649H11.2169V21.1841C11.2169 21.6345 11.5675 22 11.9995 22C12.4314 22 12.782 21.6345 12.782 21.1841V12.2649H17.2174C17.5033 12.2649 17.7652 12.1028 17.9019 11.8428C18.0406 11.5828 18.0312 11.2652 17.88 11.0139L12.662 2.38075C12.518 2.1436 12.2687 2 11.9995 2C11.7303 2 11.4809 2.1436 11.3369 2.38075L6.11898 11.0139C5.96895 11.265 5.95987 11.5821 6.09707 11.8428Z'
                  fill={percentChange > 0 ? '#5CEE9C' : '#FF6464'}
                />
              </svg>
            </div>
          </div>
        )}
      </div>
      <div className='balance-profit__remaining-wrapper'>
        <p className='balance-profit__remaining-title'>Remaining balance:</p>
        <p className='balance-profit__remaining-value'>{user.usdt.toFixed(6)} USDT</p>
      </div>
    </div>
  );
};

export default BalanceProfit;
