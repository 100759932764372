import React, { useEffect, useRef, memo } from 'react';
import { Terminal } from './Terminal/Terminal';
import './TradingViewWidget.scss';
import { AssetFileds } from '../../../states/userState';
import classNames from 'classnames';
import { useRecoilValue } from 'recoil';
import { chartConnectedState } from '../../../states/chartConnectedState';

export enum Symbols {
  BTC = 'btc',
  ETH = 'eth',
  BNB = 'bnb',
}

export interface Token {
  color: string;
  assets: [];
  symbol: Symbols;
  name: string;
  icon: JSX.Element;
  widget: string;
}

interface TradingViewWidgetProps {
  isOpen: boolean;
  currentToken: AssetFileds;
  currentPrice: string;
}

function TradingViewWidget({ isOpen, currentToken, currentPrice }: TradingViewWidgetProps) {
  const onLoadScriptRef = useRef(null);
  const container = useRef(null);
  const isChartConnected = useRecoilValue(chartConnectedState);

  useEffect(() => {
    function createWidget() {
      if (container.current !== null && isOpen && 'TradingView' in window) {
        new (window.TradingView as any).widget({
          autosize: true,
          symbol: `BINANCE:${currentToken.symbol.toLocaleUpperCase()}USDT`,
          interval: '1',
          timezone: 'Etc/UTC',
          theme: 'dark',
          style: '1',
          locale: 'en',
          enable_publishing: false,
          container_id: `tradingview_${currentToken.symbol.toLocaleUpperCase()}`,
        });
      }
    }

    if (isChartConnected) {
      createWidget();
    }
  }, [isChartConnected, isOpen, currentToken]);
  return (
    <div className={classNames('widget', { widget_opened: isOpen })}>
      <div
        className='widget-container'
        id={`tradingview_${currentToken.symbol.toLocaleUpperCase()}`}
        ref={container}
      />
      <Terminal currentPrice={currentPrice} currentToken={currentToken} />
    </div>
  );
}

export default TradingViewWidget;
