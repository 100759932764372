import React, { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { userState } from '../../states/userState';
import { useAccount } from 'wagmi';
import Skeleton from '../Skeleton/Skeleton';
import BalanceProfit from '../BalanceProfit/BalanceProfit';
import './UserBalance.scss';

export const UserBalance = () => {
  const user = useRecoilValue(userState);
  const { isConnected } = useAccount();

  const [currentBalance, setCurrentBalance] = useState<any>([]);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [totalBalance, setTotalBalance] = useState<any>(0);
  const [hasOpenedPositions, setHasOpenedPositions] = useState<any>(false);

  useEffect(() => {
    const someArray: any = [];
    setTotalBalance(0);

    if (user) {
      user &&
        user?.assets?.map((item: any) => {
          const token: any = Object.values(item)[0];

          if (Number(token.amount) > 0) {
            const objfield = { symbol: token.symbol, amount: token.amount };

            someArray.push(objfield);
          }
        });

      if (someArray.length > 0) {
        setCurrentBalance(someArray);
        setHasOpenedPositions(true);
      } else {
        setTotalBalance(user.usdt);
        setHasOpenedPositions(false);
      }
    }
  }, [user]);

  useEffect(() => {
    const someArray: any = [];

    user &&
      user?.assets?.map((item: any) => {
        const token: any = Object.values(item)[0];

        if (Number(token.amount) > 0) {
          const objfield = { symbol: token.symbol, amount: token.amount };

          someArray.push(objfield);
        }
      });

    if (hasOpenedPositions) {
      const id = setInterval(() => {
        getTotalBalance(currentBalance).then((totalBalance) => {
          if (totalBalance) {
            setIsLoaded(true);
            setTotalBalance(`${totalBalance.toFixed(6)}`);
          }
        });
      }, 5000);

      return () => {
        clearInterval(id);
      };
    } else {
      if (user) {
        setIsLoaded(true);
        setTotalBalance(user.usdt);
      }
    }
  }, [currentBalance, hasOpenedPositions, user]);

  async function getTotalBalance(balances: any) {
    if (balances.length > 0) {
      const symbols = balances.map((balance: any) => `"${balance.symbol}USDT"`);
      const prices = await fetch(
        `https://api.binance.com/api/v3/ticker/price?symbols=[${symbols.join(',')}]`,
      ).then((response) => response.json());

      const totalBalance = balances.reduce((total: any, balance: any) => {
        const price = prices.find((p: any) => p.symbol === `${balance.symbol}USDT`).price;
        const usdValue = parseFloat(price) * parseFloat(balance.amount);

        return total + usdValue;
      }, 0);
      return totalBalance;
    }
  }

  return (
    <div className='user-balance'>
      <>
        {totalBalance && isLoaded ? (
          <>
            <BalanceProfit hasOpenedPositions={hasOpenedPositions} value={totalBalance} />
          </>
        ) : (
          <Skeleton width={200} height={50} />
        )}
      </>
    </div>
  );
};
