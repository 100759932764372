import React, { useState } from 'react';
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useAccount, useDisconnect } from 'wagmi';
import { ReactComponent as Logo } from '../../../icons/logo.svg';
import { userState } from '../../../states/userState';
import { Button } from '../../Button/Button';
import { useMediaQuery } from '../../../hooks/useMatchMedia';
import classNames from 'classnames';
import { shortenAddress } from '../../../functions/shortenAddress';
import { ReactComponent as CloseIcon } from '../../../icons/close.svg';
import { ReactComponent as MenuIcon } from '../../../icons/menu.svg';
import { ReactComponent as AvatarIcon } from '../../../icons/avatar.svg';
import { ReactComponent as LogoutIcon } from '../../../icons/logout.svg';
import { MetamaskButton } from '../../MetamaskButton/MetamaskButton';
import './Header.scss';

export const Header = () => {
  const navigator = useNavigate();
  const { pathname } = useLocation();
  const { isConnected, address } = useAccount();
  const { disconnect } = useDisconnect();
  const user = useRecoilValue(userState);
  const [isSidebarOpen, setSidebarOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const isDesktop = useMediaQuery('(max-width: 996px)');

  const navItems = [
    // {
    //   label: 'About us',
    //   dropdown: true,
    //   href: '#',
    //   isNavLink: false,
    //   links: [
    //     { label: 'Products', href: '#', isNavLink: false },
    //     { label: 'Company', href: '#', isNavLink: false },
    //     { label: 'Team', href: '#', isNavLink: false },
    //   ],
    // },
    { label: 'How DemoTrade works', href: '/#how-it-works', isNavLink: false },
    // { label: 'Referral program', href: '#', isNavLink: false },
    // { label: 'Blog', href: '#', isNavLink: false },
    // { label: 'Dashboard', hidden: !user, href: '/dashboard', isNavLink: true },
    {
      label: 'Dashboard',
      hidden: false,
      href: '/dashboard',
      isNavLink: true,
      onClick: () => pathname === '/dashboard' && navigator(0),
    },
  ];
  return (
    <header className='header'>
      <div className='header__container'>
        <Link to={'/'}>
          <Logo />
        </Link>

        <div className='header__nav'>
          {navItems.map((navItem, index) => {
            // if (navItem.dropdown) {
            //   const items = navItem.links.map((link, index) => {
            //     return (
            //       <a
            //         key={index}
            //         href={link.href}
            //         className='header__link header__dropdown-link'
            //         onClick={() => setSidebarOpen(false)}
            //       >
            //         {link.label}
            //       </a>
            //     );
            //   });
            //
            //   return (
            //     <>
            //       <span
            //         key={index}
            //         className={`header__link header__dropdown-open ${isDropdownOpen ? 'open' : ''}`}
            //         onClick={() => setIsDropdownOpen((prev) => !prev)}
            //       >
            //         {navItem.label}
            //         <div className={`${isDropdownOpen ? 'open' : ''} header__dropdown-wrapper`}>
            //           <div className='header__dropdown'>{items}</div>
            //         </div>
            //       </span>
            //     </>
            //   );
            // } else if (!navItem.isNavLink) {
            //   return (
            //     <a
            //       key={index}
            //       href={navItem.href}
            //       className='header__link'
            //       onClick={() => setSidebarOpen(false)}
            //     >
            //       {navItem.label}
            //     </a>
            //   );
            // }
            return (
              !navItem.hidden && (
                <NavLink
                  className={({ isActive }) => classNames('header__link', { header__link_active: isActive })}
                  to={navItem.href}
                  key={index}
                  onClick={() => {
                    setSidebarOpen(false);
                    navItem.onClick?.();
                  }}
                >
                  {navItem.label}
                </NavLink>
              )
            );
          })}
        </div>

        <div className='header__info-wrap'>
          {/* {isConnected && user && (
            <div className='header__userinfo'>
              <div className='header__address radius_8 text_onest-12'>
                {address && shortenAddress(address as string)}
              </div>

              <Link to={'/profile'}>
                <AvatarIcon className='header__avatar' />
              </Link>
            </div>
          )} */}

          <MetamaskButton isDefaultButton={true} className='header__btn'>
            Connect wallet
          </MetamaskButton>
        </div>
        <MenuIcon className='header__menu' onClick={() => setSidebarOpen(true)} />
        <div
          className={classNames('header__sidebar', {
            header__sidebar_visible: isSidebarOpen && isDesktop,
          })}
        >
          <div className='header__container'>
            <CloseIcon className='header__sidebar-close' onClick={() => setSidebarOpen(false)} />
          </div>
          <div className='header__sidebar-nav'>
            {navItems.map((navItem, index) => {
              // if (navItem.dropdown) {
              //   const items = navItem.links.map((link, index) => {
              //     return (
              //       <a
              //         key={index}
              //         href={link.href}
              //         className='header__link header__dropdown-link'
              //         onClick={() => setSidebarOpen(false)}
              //       >
              //         {link.label}
              //       </a>
              //     );
              //   });
              //
              //   return (
              //     <>
              //       <span
              //         key={index}
              //         className={`header__link header__dropdown-open ${isDropdownOpen ? 'open' : ''}`}
              //         onClick={() => setIsDropdownOpen((prev) => !prev)}
              //       >
              //         {navItem.label}
              //         <div className={`${isDropdownOpen ? 'open' : ''} header__dropdown-wrapper`}>
              //           <div className='header__dropdown'>{items}</div>
              //         </div>
              //       </span>
              //     </>
              //   );
              // }
              return (
                !navItem.hidden && (
                  <NavLink
                    className={({ isActive }) =>
                      classNames('header__sidebar-link', { header__link_active: isActive })
                    }
                    to={navItem.href}
                    key={index}
                    onClick={() => setSidebarOpen(false)}
                  >
                    {navItem.label}
                  </NavLink>
                )
              );
            })}
          </div>
          <div className='header__sidebar-footer'>
            <MetamaskButton isDefaultButton={true} className='header__connect header__btn'>
              Connect wallet
            </MetamaskButton>
          </div>
        </div>
      </div>
    </header>
  );
};
