import React, { useEffect, useState } from 'react';
import { userAPI } from '../../api/user';
import { Token } from '../../pages/MainPage/ConstructorChart/TradingViewWidget';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { userState } from '../../states/userState';
import cn from 'classnames';
import { toast } from 'react-toastify';
import { getTotalPrice } from '../../functions/getTotalPrice';
import { emptyTokenData } from '../../constants/basic';
import { useAccount } from 'wagmi';
import './PositionItem.scss';
import { positionsState } from '../../states/positionsState';
import { useUpdatePositions } from '../../hooks/useUpdatePositions';
import { useUpdateHistory } from '../../hooks/useUpdateHistory';
import { useInterval } from '../../hooks/useInterval';
import { useCheckPositions } from '../../hooks/useCheckPositions';

interface PositionItemProps {
  buyValueUsdt: string;
  isButton?: boolean;
  currentToken: any;
  currentPrice?: string;
  // position: any;
}

export const PositionItem = ({
  buyValueUsdt,
  currentPrice,
  currentToken,
  // position,
  isButton = false,
}: PositionItemProps) => {
  const [user, setUser] = useRecoilState(userState);
  const [isPlus, setIsPlus] = useState(true);
  const [usdt, setUsdt] = useState<any>('');
  const [percent, setPercent] = useState(0);
  const [price, setPrice] = useState(0);
  // const { address } = useAccount();
  const setPositions = useSetRecoilState(positionsState);
  const updatePositions = useUpdatePositions();
  const updateHistory = useUpdateHistory();

  useEffect(() => {
    fetch(`https://api.binance.com/api/v3/ticker/price?symbol=${currentToken.asset.symbol.toUpperCase()}USDT`)
      .then((response) => response.json())
      .then((data) => setPrice(data.price));
  }, []);

  useEffect(() => {
    // price && getResult();
    price && calculateProfitWithPosition(currentToken.price, price, currentToken.amount, currentToken.type);
  }, [price, currentToken]);

  // if (!address || !user) return null;
  // const position = user[currentToken.symbol];
  const onClosePositionClick = () => {
    userAPI
      .closePosition(user.ethAddress, currentToken.asset.symbol, currentToken.id)
      .then((res) => {
        const amount = currentToken.amount.toString();
        toast.success(
          `You have successfully sold ${currentToken.amount} ${currentToken.asset.symbol} for ${getTotalPrice(
            amount,
            price.toString(),
          )}`,
        );
        updatePositions();
        updateHistory();
        // if (res.data['Long possition'] === 'Close!' || res.data['Short possition'] === 'Close!') {

        //   userAPI
        //     .getUser(user.ethAddress)
        //     .then((res) => {
        //       setUser(res.data);
        //     })
        //     .catch((error) => console.log(error));

        // }
      })
      .catch(() => toast.error('Error. Try again'));
  };

  // const getResult = () => {
  //   const result =
  //     currentToken.type === 'long' ? Number(price) - currentToken.price : currentToken.price - Number(price);

  //   const resultPercent = Number(((result * 100) / Number(price)).toFixed(2));
  //   const resultUsdt = (currentToken.amount * resultPercent) / 100;

  //   setPercent(resultPercent);

  //   if (resultUsdt > 0) {
  //     setIsPlus(true);
  //     setUsdt(`+${result.toFixed(6)}`);
  //   } else {
  //     setIsPlus(false);
  //     setUsdt(`${result.toFixed(6)}`);
  //   }
  // };
  const checkPositions = useCheckPositions(currentToken.asset.symbol);
  useInterval(10000, checkPositions);

  function calculateProfitWithPosition(
    priceBuy: number,
    priceCurrent: number,
    quantity: number,
    position: string,
  ) {
    const diff = position === 'long' ? priceCurrent - priceBuy : priceBuy - priceCurrent;

    const profit = diff * quantity;
    const profitPercent = (diff / priceBuy) * 100;

    profit > 0 ? setIsPlus(true) : setIsPlus(false);

    setPercent(Number(profitPercent.toFixed(2)));
    setUsdt(Number(profit.toFixed(2)));
  }
  console.log(currentToken);
  return (
    <div className={cn('position')}>
      <div className='position__row'>
        <div className='position__info'>
          {currentToken.icon}
          <div>
            <div className='position__name'>{currentToken.name}</div>
            {currentToken.price && (
              <div className='position__enter-price'>Opened price: {currentToken.price} USDT</div>
            )}

            <div
              className={cn('position__type text_14', { position__type_red: currentToken.type === 'short' })}
            >
              {currentToken && currentToken.type}
            </div>
          </div>
        </div>
        <div className='position__numbers'>
          <div className='position__price text_16'>
            {currentToken.amount} {currentToken.asset.symbol.toUpperCase()}{' '}
            <span style={{ color: '#868788' }}>|</span> {currentToken && Number(currentToken.usdt).toFixed(2)}{' '}
            USDT
          </div>
          <div className={cn('position__result text_13', { position__result_red: !isPlus })}>
            {usdt} USDT · {percent}%
          </div>
        </div>
      </div>

      {isButton && (
        <div onClick={onClosePositionClick} className='position__button text_16'>
          Close position
        </div>
      )}
    </div>
  );
};
