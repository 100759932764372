import React, { Dispatch, SetStateAction, useState } from 'react';
import classNames from 'classnames';
import './Pagination.scss';

interface PaginationProps {
  setCurrentPage: Dispatch<SetStateAction<number>>;
  totalPages: number;
}

export const Pagination = ({ setCurrentPage, totalPages }: PaginationProps) => {
  const [actveButton, setActiveButton] = useState<number>(0);

  const handlePageChange = (pageNum: number) => {
    setCurrentPage(pageNum);
    setActiveButton(pageNum);
  };

  return (
    <>
      {totalPages > 1 && (
        <div className='pagination'>
          {[...Array(totalPages)].map((_, i) => (
            <button
              className={classNames('pagination__button', {
                pagination__button_active: i === actveButton,
              })}
              disabled={actveButton === i}
              key={i}
              onClick={() => handlePageChange(i)}
            >
              {i + 1}
            </button>
          ))}
        </div>
      )}
    </>
  );
};
