import axios from 'axios';

const user = axios.create({
  baseURL: process.env.REACT_APP_PUBLIC_API + 'users',
});

type TBuyTokenParams = {
  address: string;
  currentPrice: string;
  buyCountValue: string;
  symbol: string;
  takeProfit: string;
  stopLoss: string;
};

type TSellShortParams = {
  address: string;
  currentPrice: string;
  sellCountValue: string;
  symbol: string;
  takeProfit: string;
  stopLoss: string;
};

type TCheckParams = {
  address: string;
  currency: string;
};

export const userAPI = {
  createUser: (address: string) => {
    return user.post(`/create_user`, null, {
      params: {
        eth_address: address,
      },
    });
  },

  getUser: (userAddress: string) => {
    return user.get(`/${userAddress}`);
  },

  buyToken: ({ address, symbol, currentPrice, buyCountValue, stopLoss, takeProfit }: TBuyTokenParams) => {
    const access_token = window.localStorage.getItem('access_token');
    return user.post(`${address}/long`, null, {
      headers: { Authorization: access_token },
      params: {
        price: currentPrice,
        amount: buyCountValue,
        currency: symbol.toLowerCase(),
        take_profit: takeProfit,
        stop_loss: stopLoss,
      },
    });
  },
  sellToken: ({ address, currentPrice, symbol, sellCountValue, stopLoss, takeProfit }: TSellShortParams) => {
    const access_token = window.localStorage.getItem('access_token');
    return user.post(`${address}/short`, null, {
      headers: { Authorization: access_token },
      params: {
        price: currentPrice,
        amount: sellCountValue,
        currency: symbol.toLowerCase(),
        take_profit: takeProfit,
        stop_loss: stopLoss,
      },
    });
  },
  closePosition: (address: string, symbol: string, positionId: string) => {
    const access_token = window.localStorage.getItem('access_token');
    return user.post(`${address}/close`, [positionId], {
      headers: { Authorization: access_token },
      params: {
        currency: symbol.toLowerCase(),
      },
    });
  },
  check: ({ address, currency }: TCheckParams) => {
    return user.post(`${address}/check`, null, {
      params: {
        currency: currency.toLowerCase(),
      },
    });
  },
  getTotalAssetsPrice: (address: string, btcPrice: string, ethPrice: string, bnbPrice: string) => {
    return user.get('/amount', {
      params: {
        eth_address: address,
        price_btc: btcPrice,
        price_eth: ethPrice,
        price_bnb: bnbPrice,
      },
    });
  },
  getBalance: (address: string, btcPrice: string, ethPrice: string, bnbPrice: string) => {
    return user.get('/balance', {
      params: {
        eth_address: address,
        price_btc: btcPrice,
        price_eth: ethPrice,
        price_bnb: bnbPrice,
      },
    });
  },
};
