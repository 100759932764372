import React, { ButtonHTMLAttributes, ReactNode } from 'react';
import classNames from 'classnames';

import './Button.scss';

type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  isBig?: boolean;
  icon?: ReactNode;
  bg?: 'gradient' | 'green' | 'red';
  bold?: boolean;
  onClick?: () => void;
  children?: ReactNode;
  innerClassName?: string;
};

export const Button = ({
  children,
  bg,
  isBig,
  icon,
  onClick,
  className,
  innerClassName,
  bold = true,
}: ButtonProps) => {
  return (
    <button
      className={classNames(
        'button',
        {
          button_gradient: bg === 'gradient',
          button_green: bg === 'green',
          button_red: bg === 'red',
          button_big: isBig,
        },
        className,
      )}
      onClick={onClick}
    >
      <div className='button__wrap'>
        {icon} <span className={classNames('button__label', innerClassName)}>{children}</span>
      </div>
    </button>
  );
};
