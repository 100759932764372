import React, { useEffect, useState, useContext, PropsWithChildren } from 'react';
import { useAccount } from 'wagmi';
import { useRecoilState } from 'recoil';
import { userState } from '../../states/userState';
import { userAPI } from '../../api/user';
import './MetamaskButton.scss';
import { securityAPI } from '../../api/security';
import { AddEmailContext } from '../EmailForm/AddEmailContext';
import { useWeb3Modal } from '@web3modal/wagmi/react';

interface MetamaskButtonProps extends PropsWithChildren {
  isDefaultButton?: boolean;
  className?: string;
  isBig?: boolean;
  onClick?: () => void;
}

export const MetamaskButton = ({ isDefaultButton, className, isBig, children }: MetamaskButtonProps) => {
  const { open } = useWeb3Modal();
  const { setShowModal, showModal } = useContext(AddEmailContext);
  const [isMobileDevice, setIsMobileDevice] = useState(false);
  const setProfile = useRecoilState(userState)[1];
  const { address, isConnected } = useAccount();
  useEffect(() => {
    if (window.innerWidth <= 768) {
      setIsMobileDevice(true);
    } else {
      setIsMobileDevice(false);
    }
  }, []);

  useEffect(() => {
    if (isConnected) {
      (async () => {
        if (address) {
          const isUserExists: boolean = await userAPI
            .getUser(address)
            .then(() => true)
            .catch(() => false);
          let nonce = '';
          if (isUserExists) {
            nonce = (await securityAPI.getNonce(address)).data.nonce;
          } else {
            nonce = (await securityAPI.registerUser(address)).data.nonce;
          }
          const { access_token, token_type } = (
            await securityAPI.signIn({
              nonce: nonce,
              signature: '',
              wallet_addr: address,
            })
          ).data;
          window.localStorage.setItem('access_token', `${token_type} ${access_token}`);
          const { data: profileUpdate } = await userAPI.getUser(address);
          setProfile(profileUpdate);
          try {
            const { data: checkData } = await securityAPI.checkEmail(`${token_type} ${access_token}`);
            if (!showModal && checkData.message !== 'Ok' && window.localStorage.getItem('access_token')) {
              setShowModal(true);
            }
          } catch (e) {
            console.log(e);
          }
        }
      })();
    }
  }, [isConnected]);

  const handleAuth = async () => {
    try {
      await open();
    } catch (e) {
      console.log(e);
      if (e.name === 'ConnectorNotFoundError' && isMobileDevice) {
        window.location.href = 'https://metamask.app.link/dapp/beta.demotrade.itworkin.com/';
      }
    }
  };

  return (
    <div className='meta-btn'>
      {isConnected ? (
        <w3m-button balance='hide'></w3m-button>
      ) : (
        <button onClick={() => open()} className='metamask-btn'>
          Connect Wallet
        </button>
      )}
    </div>
  );
};
