import React from 'react';
import './Faq.scss';
import { faqData } from '../../mocks/faqMock';
import { FaqItem } from './FaqItem';

const Faq = () => {
  return (
    <div className='faq' id='faq'>
      <img className='faq__back' src='/images/bg-faq-pattern.png' alt='background pattern' />
      <div className='container'>
        <p className='faq__main-title'>FAQ</p>
        <div className='faq__list'>
          {faqData.map((item: any) => (
            <FaqItem key={item.title} title={item.title} text={item.text} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Faq;
