import React, { useState } from 'react';

import { useAccount } from 'wagmi';
import { FirstScreen } from './FirstScreen/FirstScreen';
import { AboutProject } from './AboutProject/AboutProject';
import './MainPage.scss';
import { HowInfo } from './HowInfo/HowInfo';
import Faq from '../../components/Faq/Faq';
import { Share } from './Share/Share';
import { EmailBlock } from './EmailBlock/EmailBlock';
import { Reviews } from './Reviews/Reviews';

export const MainPage = () => {
  const { isConnected, address } = useAccount();
  const [inputValue, setInputValue] = useState('');

  return (
    <div className='main-page__container'>
      <FirstScreen />
      <AboutProject />
      <HowInfo />
      {/* <Reviews /> */}
      <Share />
      <Faq />
      {/* <EmailBlock /> */}
    </div>
  );
};
