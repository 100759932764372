import React from 'react';
import { useMediaQuery } from '../../../hooks/useMatchMedia';
import { ReactComponent as LuggageIcon } from '../../../icons/luggage.svg';
import { ReactComponent as DollarIcon } from '../../../icons/dollar.svg';
import { ReactComponent as BitcoinIcon } from '../../../icons/bitcoin.svg';
import './AboutProject.scss';

export const AboutProject = () => {
  const isDesktop = useMediaQuery('(max-width: 996px)');

  return (
    <div className='about-project container'>
      <h2 className='about-project__title'>become a PRO without financial loss</h2>
      <p className='about-project__description'>get virtual $1m in USDT with DemoTrade</p>
      <div className='about-project__card-group'>
        <div className='about-project__card'>
          <LuggageIcon className='about-project__icon' />
          <p className='about-project__card-title'>develop your crypto portfolio</p>
          <p className='about-project__card-signature'>track virtual investment</p>
        </div>
        <div className='about-project__card'>
          <DollarIcon className='about-project__icon' />
          <p className='about-project__card-title'>enhance trading skills</p>
          <p className='about-project__card-signature'>use just virtual funds</p>
        </div>
        <div className='about-project__card'>
          <BitcoinIcon className='about-project__icon' />
          <p className='about-project__card-title'>
            {!isDesktop ? 'compete, trade, earn' : 'take part in trading competitions'}
          </p>
          <p className='about-project__card-signature'>
            {!isDesktop ? 'get actual crypto' : 'compete, trade, earn'}
          </p>
        </div>
      </div>
    </div>
  );
};
