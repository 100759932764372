import React, { useState } from 'react';
import { ReactComponent as ArrowSvg } from '../../icons/whiteArrow.svg';
import classNames from 'classnames';

interface FaqItemProps {
  title: string;
  text: string;
}

export const FaqItem = ({ title, text }: FaqItemProps) => {
  const [isOpened, setOpened] = useState(false);

  return (
    <div className='faq__item' onClick={() => setOpened(!isOpened)}>
      <div className='faq__header'>
        <p className='faq__title'>{title}</p>
        <ArrowSvg className={classNames('faq__arrow', { faq__arrow_opened: isOpened })} />
      </div>
      <div className={classNames('faq__body', { faq__body_opened: isOpened })}>
        <p className='faq__text'>{text}</p>
      </div>
    </div>
  );
};
