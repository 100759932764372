import { createWeb3Modal } from '@web3modal/wagmi/react';
import { walletConnectProvider } from '@web3modal/wagmi';
import { configureChains, createConfig } from 'wagmi';
import { publicProvider } from 'wagmi/providers/public';
import { mainnet } from 'wagmi/chains';
import { WalletConnectConnector } from 'wagmi/connectors/walletConnect';
import { MetaMaskConnector } from 'wagmi/connectors/metaMask';
import { InjectedConnector } from 'wagmi/connectors/injected';

const projectId = 'd80fc2c5d33de374df2d44f4c8321f42';

// 2. Create wagmiConfig
const { chains, publicClient, webSocketPublicClient } = configureChains(
  [mainnet],
  [walletConnectProvider({ projectId }), publicProvider()],
);

export const wagmiConfig = createConfig({
  autoConnect: true,
  webSocketPublicClient,
  connectors: [
    new WalletConnectConnector({
      chains,
      options: {
        projectId,
        showQrModal: false,
      },
    }),
    new InjectedConnector({ chains }),
    new MetaMaskConnector({ chains }),
  ],
  publicClient,
});

// 3. Create modal
createWeb3Modal({
  wagmiConfig,
  projectId,
  chains,
  themeVariables: {
    '--w3m-z-index': 10000,
  },
});
