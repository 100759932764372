import { useRecoilValue, useSetRecoilState } from 'recoil';
import { positionsState } from '../states/positionsState';
import { userState } from '../states/userState';
import { assetsAPI } from '../api/assets';
import { useCallback } from 'react';

export const useUpdatePositions = () => {
  const setPositions = useSetRecoilState(positionsState);
  const user = useRecoilValue(userState);
  const updatePositions = useCallback(async () => {
    const someArray: any = [];
    const { data } = await assetsAPI.getPositions(user.ethAddress);
    data.map((item: any) => {
      const token: any = item;

      if (Number(token.amount) > 0) {
        someArray.push(token);
      }
    });
    setPositions(someArray);
  }, [setPositions, user]);
  return updatePositions;
};
