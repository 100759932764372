import React, { useState } from 'react';
import './HowInfo.scss';

import Marker from './../../../icons/marker-pin.svg';
import { MetamaskButton } from '../../../components/MetamaskButton/MetamaskButton';
import browserDetect from 'browser-detect';

export const HowInfo = () => {
  const [isHovering, setIsHovering] = useState(0);
  const { name: browserName } = browserDetect();
  const handleMouseLeave = () => {
    setIsHovering(0);
  };
  const openExtentionPage = () => {
    switch (browserName) {
      case 'firefox':
        window.open(
          'https://addons.mozilla.org/ru/firefox/addon/ether-metamask/?utm_source=addons.mozilla.org&utm_medium=referral&utm_content=search',
          '_blank',
          'noreferrer',
        );
        break;
      case 'opera':
        window.open('https://addons.opera.com/ru/extensions/details/metamask-10/', '_blank', 'noreferrer');
        break;
      default:
        window.open(
          'https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn',
          '_blank',
          'noreferrer',
        );
    }
  };
  return (
    <div className='how-info' id='how-it-works'>
      <picture>
        <source srcSet='/images/bg-howinfo-pattern-tab.png' media='(max-width: 992px)' />
        <img className='how-info__back' src='/images/bg-howinfo-pattern.png' alt='background pattern' />
      </picture>
      <div className='container'>
        <h2 className='how-info__title'>how DemoTrade works</h2>
        <p className='how-info__subtitle'>just a few steps to big heights</p>

        <ul className='how-info__list'>
          <li
            className='how-info__list-item'
            onMouseEnter={() => setIsHovering(1)}
            onMouseLeave={handleMouseLeave}
          >
            <div
              className='how-info__list-item-number'
              style={{
                backgroundImage: isHovering === 1 ? 'url("images/how1_2.png")' : 'url("images/how1_1.png")',
              }}
            ></div>
            <div className='how-info__list-item-info-block'>
              <p className='how-info__list-item-info'>
                <img src={Marker} alt='marker' />
                You are here
              </p>
              <p style={{ marginBottom: 10 }} className='how-info__list-item-desc'>
                Connect your MetaMask wallet
              </p>
              <MetamaskButton
                onClick={() => {
                  openExtentionPage();
                }}
                isDefaultButton={window.innerWidth < 1024}
                className='how-info__list-item-btn'
              >
                Don’t have a wallet yet? Create one now
              </MetamaskButton>
            </div>
            <img src='images/howinfo1.png' alt='' className='how-info__list-item-img' />
          </li>

          <li
            className='how-info__list-item'
            onMouseEnter={() => setIsHovering(2)}
            onMouseLeave={handleMouseLeave}
          >
            <div
              className='how-info__list-item-number'
              style={{
                backgroundImage: isHovering === 2 ? 'url("images/how2_2.png")' : 'url("images/how2_1.png")',
              }}
            ></div>
            <div className='how-info__list-item-info-block'>
              <p className='how-info__list-item-desc'>Go to the dashboard</p>
            </div>
            <img src='images/howinfo2.png' alt='' className='how-info__list-item-img' />
          </li>
          <li
            className='how-info__list-item no-out'
            onMouseEnter={() => setIsHovering(3)}
            onMouseLeave={handleMouseLeave}
          >
            <div
              className='how-info__list-item-number'
              style={{
                backgroundImage: isHovering === 3 ? 'url("images/how3_2.png")' : 'url("images/how3_1.png")',
              }}
            ></div>
            <div className='how-info__list-item-info-block'>
              <p className='how-info__list-item-desc'>Get your $1M for 100+ crypto assets</p>
            </div>
            <img src='images/howinfo3.png' alt='' className='how-info__list-item-img' />
          </li>

          <li
            className='how-info__list-item no-out'
            onMouseEnter={() => setIsHovering(4)}
            onMouseLeave={handleMouseLeave}
          >
            <div
              className='how-info__list-item-number'
              style={{
                backgroundImage: isHovering === 4 ? 'url("images/how4_2.png")' : 'url("images/how4_1.png")',
              }}
            ></div>
            <div>
              <p className='how-info__list-item-desc'>Track, trade, learn & EARN</p>
            </div>
            <img src='images/howinfo4.png' alt='' className='how-info__list-item-img' />
          </li>
        </ul>

        {/* <div className='how-info__item'>
        <div className='how-info__item-content'>
          <p className='how-info__item-span'>Where to begin?</p>
          <p className='how-info__item-title'>MetaMask</p>
          <p className='how-info__item-text'>Connect your wallet</p>
        </div>
        <div className='how-info__item-image'>
          <img src='/images/how-first.png' alt='info' />
          <img className='how-info__blur' src='/images/blur.png' alt='blur' />
        </div>
      </div>

      <div className='how-info__item how-info__item_reversed'>
        <div className='how-info__item-content'>
          <p className='how-info__item-span'>What`s next?</p>
          <p className='how-info__item-title how-info__item-title_red'>Get your $1m</p>
          <p className='how-info__item-text'>in crypto to buy long, or sell short over 200 crypto assets</p>
        </div>
        <div className='how-info__item-image'>
          <img src='/images/how-second.png' alt='info' />
          <img className='how-info__blur' src='/images/blur.png' alt='blur' />
        </div>
      </div>

      <div className='how-info__item'>
        <div className='how-info__item-content'>
          <p className='how-info__item-span'>What is the result?</p>
          <p className='how-info__item-title how-info__item-title_purple'>Earn easily</p>
          <p className='how-info__item-text'>Track your portfolio, trade, rebalance, become a PRO</p>
        </div>
        <div className='how-info__item-image'>
          <img src='/images/how-third.png' alt='info' />
          <img className='how-info__blur' src='/images/blur.png' alt='blur' />
        </div>
      </div> */}
      </div>
    </div>
  );
};
