import axios from 'axios';

const api = axios.create({ baseURL: process.env.REACT_APP_PUBLIC_API + 'security/users' });

interface SignInResponse {
  message: string;
  access_token: string;
  token_type: string;
}

export const securityAPI = {
  async registerUser(wallet_addr: string) {
    return await api.post<{ nonce: string }>(
      '/register',
      {},
      {
        params: {
          wallet_addr,
        },
      },
    );
  },
  async getNonce(wallet_addr: string) {
    return await api.get<{ nonce: string }>(`/${wallet_addr}/nonce`);
  },
  async signIn(data: { nonce: string; wallet_addr: string; signature: string }) {
    return await api.post<SignInResponse>('/signin', data);
  },
  async check(access_token: string) {
    return await api.get('/token/check', { headers: { Authorization: access_token } });
  },
  async addEmail(email: string, accessToken: string) {
    return await api.patch(
      `/email?email=${email}`,
      {},
      {
        headers: { Authorization: accessToken },
      },
    );
  },
  async checkEmail(accessToken: string) {
    return await api.get(`/email/check`, {
      headers: { Authorization: accessToken },
    });
  },
};
