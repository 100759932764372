import React, { useCallback, useEffect, useState } from 'react';
import { SortArrows } from '../../../components/SortArrows/SortArrows';
import { AssetRow } from '../AssetRow/AssetRow';
import { Pagination } from '../../../components/Pagination/Pagination';
import { useRecoilState } from 'recoil';
import { AssetItem, userState } from '../../../states/userState';
import { ReactComponent as SearchIcon } from '../../../icons/search-icon.svg';
import classNames from 'classnames';
import './AssetList.scss';
import { assetsAPI } from '../../../api/assets';

export interface Price {
  symbol: string;
  price: string;
}

export interface AssetListProps {
  searchValue?: string;
}

export const AssetList = ({ searchValue }: AssetListProps) => {
  const [user, setUser] = useRecoilState(userState);
  const [assetsList, setAssetsList] = useState<AssetItem[]>([]);
  const [sortByName, setSortByName] = useState<any>(null);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(100);
  const [currentData, setCurrentData] = useState<AssetItem[]>([]);
  const [search, setSearch] = useState<string>('');
  const [childrenArray, setChildrenArray] = useState<any>([]);

  useEffect(() => {
    if (childrenArray.length === 100) {
      setSortByName(null);

      childrenArray.forEach(([value, symbol]: any) => {
        childrenArray[symbol.toLowerCase()] = value;
      });

      const prices: Record<string, string> = {};
      childrenArray.forEach(([value, symbol]: any) => {
        prices[symbol.toLowerCase()] = value;
      });

      const newArray = currentData.map((obj: any) => {
        const newObj = Object.assign({}, obj);
        Object.entries(obj).forEach(([symbol, data]) => {
          const symbolKey = symbol.toLowerCase();
          if (prices[symbolKey]) {
            const dataCopy = Object.assign({}, data) as Record<string, unknown>;
            dataCopy.last24 = parseFloat(prices[symbolKey]);
            newObj[symbol] = dataCopy;
          }
        });
        return newObj;
      });
      setAssetsList(newArray);
    }
  }, [childrenArray, currentData]);

  useEffect(() => {
    if (user) {
      (async () => {
        const { data } = await assetsAPI.getAllAssets();
        const sortedCryptocurrencies =
          data.sort((a: any, b: any) => {
            const [aKey] = Object.keys(a);
            const [bKey] = Object.keys(b);
            const aAmount = a[aKey].amount !== '' ? parseFloat(a[aKey].amount) : 0;
            const bAmount = b[bKey].amount !== '' ? parseFloat(b[bKey].amount) : 0;

            const amountDiff = bAmount - aAmount;
            if (amountDiff !== 0) {
              return amountDiff;
            } else {
              return 0;
            }
          }) ?? [];
        setAssetsList(sortedCryptocurrencies);
      })();
    }
  }, [user]);

  const handleSearch = useCallback(() => {
    // console.log(currentData);
    const filteredAssets = assetsList.filter((item) => {
      if (!search || search === '') return true;
      return (
        item.tag.includes(search.toLowerCase()) || item.name.toLowerCase().includes(search.toLowerCase())
      );
    });
    setCurrentData(filteredAssets);
  }, [assetsList, search]);

  useEffect(() => {
    const keyDown = (e: KeyboardEvent) => {
      const { key } = e;
      if (key === 'Enter') {
        handleSearch();
      }
    };
    window.addEventListener('keypress', keyDown);

    return () => window.removeEventListener('keypress', keyDown);
  }, [handleSearch, search]);

  useEffect(() => {
    const startIndex = currentPage * rowsPerPage;
    const endIndex = startIndex + rowsPerPage;
    assetsList && setTotalPages(Math.ceil(assetsList.length / rowsPerPage));
    assetsList && setCurrentData(assetsList?.slice(startIndex, endIndex));
  }, [currentPage, rowsPerPage, sortByName, assetsList]);

  return (
    <div className='asset-list__overflow'>
      <div className={'asset-list'}>
        <div className='asset-list__filter-form'>
          <div className='asset-list__filter-search filter-search'>
            <input
              type='text'
              className='filter-search__input'
              placeholder='Coin search'
              onChange={({ target: { value } }) => setSearch(value)}
              value={search}
            />
            <button className='filter-search__button' onClick={handleSearch}>
              <SearchIcon />
            </button>
          </div>
          {/* <div className='asset-list__filter-buttons'>
            <button
              className={classNames('asset-list__filter-button', {
                'asset-list__filter-button_active': searchType === 0,
              })}
              onClick={resetFilter}
            >
              All
            </button>
            <button className='asset-list__filter-button'>Popular</button>
            <button
              className={classNames('asset-list__filter-button', {
                'asset-list__filter-button_active': searchType === 2,
              })}
              onClick={on24Click('down')}
            >
              Down
            </button>
            <button
              className={classNames('asset-list__filter-button', {
                'asset-list__filter-button_active': searchType === 3,
              })}
              onClick={on24Click('up')}
            >
              Up
            </button>
          </div> */}
        </div>

        {currentData && currentData.length !== 0 ? (
          currentData.map((token: AssetItem, i: any) => {
            if (Object.keys(token)[0] === 'cro') return null;
            return (
              <AssetRow
                childrenArray={childrenArray}
                setChildrenArray={setChildrenArray}
                key={i}
                currentToken={token}
              />
            );
          })
        ) : (
          <div className='asset-list__empty'>
            <p className='asset-list__empty-title'>No results found</p>
            <p className='asset-list__empty-subtitle'>Adjust your request and try again</p>
          </div>
        )}

        <Pagination setCurrentPage={setCurrentPage} totalPages={totalPages} />
      </div>
    </div>
  );
};
