import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useRecoilState } from 'recoil';
import { useAccount } from 'wagmi';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Route, Routes } from 'react-router-dom';
import { MainPage } from './pages/MainPage/MainPage';
import { Header } from './components/layouts/Header/Header';
import { userState } from './states/userState';
import { userAPI } from './api/user';
import { Dashboard } from './pages/Dashboard/Dashboard';
import './App.scss';
import { Footer } from './components/layouts/Footer/Footer';
import { securityAPI } from './api/security';
import { AddEmailModal } from './components/EmailForm/AddEmailModal';
import { AddEmailContext, TAddEmailContext } from './components/EmailForm/AddEmailContext';

export const App = () => {
  const { isConnected, address } = useAccount();
  const [showAddEmailModal, setshowAddEmailModal] = useState(false);
  const showModal = useRef(showAddEmailModal);

  showModal.current = showAddEmailModal;
  const setProfile = useRecoilState(userState)[1];

  const contextValue = useMemo<TAddEmailContext>(
    () => ({
      setShowModal: setshowAddEmailModal,
      showModal: showAddEmailModal,
    }),
    [showAddEmailModal],
  );

  useEffect(() => {
    const bootstrap = async () => {
      try {
        const access_token = window.localStorage.getItem('access_token');
        if (access_token && address) {
          const addr = (await securityAPI.check(access_token)).data.user_addr;
          const user = (await userAPI.getUser(addr)).data;
          setProfile(user);
          return;
        }
        setProfile(null);
        window.localStorage.removeItem('access_token');
      } catch (e) {
        return false;
      }
    };
    bootstrap();
  }, [isConnected, setProfile, address]);

  useEffect(() => {
    const access_token = window.localStorage.getItem('access_token');
    if (access_token) {
      const intervalPopup = setInterval(
        () => {
          (async () => {
            try {
              const { data } = await securityAPI.checkEmail(access_token);
              if (!showAddEmailModal && data.message !== 'Ok') {
                setshowAddEmailModal(true);
              }
            } catch (e) {
              console.log(e);
            }
          })();
        },
        1000 * 60 * 60,
      );
      return () => clearInterval(intervalPopup);
    }
  }, [isConnected, showAddEmailModal]);

  return (
    <AddEmailContext.Provider value={contextValue}>
      <Header />
      <main className={'main-page'}>
        {/* <div */}
        {/* className={cn('main-page__connect radius_24', { 'main-page__connect_connected': isConnected })} */}
        {/* > */}
        {/* {isConnected ? ( */}
        {/* <div className={'main-page__user-info'}> */}
        {/* <img src='/images/user-avatar.png' alt='Avatar' /> */}
        {/* <div className='main-page__connect-address radius_8 text_onest-12'> */}
        {/* {address && address.slice(0, 23)} */}
        {/* {address && shortenAddress(address)} */}
        {/* </div> */}
        {/* </div> */}
        {/* ) : ( */}
        {/* <h2 className={'main-page__connect-title'}>To start trading register with</h2> */}
        {/* )} */}
        {/* <MetamaskButton /> */}
        {/* </div> */}
        <Routes>
          <Route path={'/'} element={<MainPage />} />
          <Route path={'/dashboard'} element={<Dashboard />} />

          {/* <Route path={'/how-it-works'} element={<HowItWorks />} />s */}
          {/* <Route path={'/about'} element={<About />} /> */}
          {/*<Route path={'/profile'} element={<Profile />} />*/}
        </Routes>
      </main>
      <Footer />
      <AddEmailModal isOpen={showAddEmailModal} handleClose={() => setshowAddEmailModal(false)} />
      <ToastContainer position='bottom-right' theme='dark' />
    </AddEmailContext.Provider>
  );
};
