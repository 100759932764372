import axios from 'axios';
import React, { useEffect } from 'react';
import { useAccount } from 'wagmi';
import './AssetsBar.scss';
import classNames from 'classnames';

interface AssetsBarProps {
  assets: any;
}

const AssetsBar = ({ assets }: AssetsBarProps) => {
  const { isConnected } = useAccount();
  // const [first, setfirst] = useState(second)
  // const assetsList = positions.map((item: any, i) => {
  //   // const positionInfo = tokens.find((x) => x.symbol === item.symbol);
  //   return (
  //     <div className={'main-info__list-item'} key={i}>
  //       <div style={{ backgroundColor: `${item?.color}` }} />
  //       {item?.name}
  //     </div>
  //   );
  // });

  useEffect(() => {
    // console.log(assets);
  }, [assets]);

  async function getPercent(asset: any) {
    try {
      const response = await axios.get(
        `https://api.binance.com/api/v3/ticker/price?symbol=${asset.symbol}USDT`,
      );
      return response.data.price;
    } catch (error) {
      console.log(error);
      return 0;
    }
  }

  const handleClick = async (asset: any) => {
    const result = await getPercent(asset);
    const newResult: any = Number(result).toFixed(0);
    const assetPrice: number = newResult * asset.amount;

    return (assetPrice * 100) / 4400;
    // return totalAssetsPrice && `${(valueUsdt * 100) / totalAssetsPrice['Sum USDT']}%`;
  };

  return (
    <div className='assets-bar'>
      {assets && (
        <div className='assets-bar__list'>
          <div
            className={classNames('assets-bar__bar', {
              'assets-bar__bar_one': assets.length === 1,
              'assets-bar__bar_not-one': assets.length > 1,
            })}
          >
            {assets.map((item: any, i: number) => {
              const total = assets.reduce((acc: any, obj: any) => acc + obj.usdt, 0);
              const percent = ((item.usdt / total) * 100).toFixed(0);

              return (
                <div key={i} style={{ width: `${Number(percent)}%`, backgroundColor: item?.color }} />
                // <div key={i} style={{ width: getPercent(item?.usdt, item), backgroundColor: item?.color }} />
              );
            })}
          </div>
          <div className='assets-bar__list-row'>
            {assets.map((item: any, i: number) => {
              return (
                <div className={'main-info__list-item'} key={i}>
                  <div style={{ backgroundColor: `${item?.color}` }} />
                  {item?.name}
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default AssetsBar;
