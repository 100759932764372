import axios from 'axios';

const assets = axios.create({
  baseURL: process.env.REACT_APP_PUBLIC_API + 'assets',
});

export const assetsAPI = {
  getAllAssets: () => {
    return assets.get(`/`);
  },
  getPositions: (ethAddress: string) => {
    return assets.get(`/stats/${ethAddress}`);
  },
  getHistory: (ethAddress: string) => {
    return axios.get(`${process.env.REACT_APP_PUBLIC_API}history/users/${ethAddress}`);
  },
};
