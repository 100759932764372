import React, { useState } from 'react';
import classNames from 'classnames';
import './EmailForm.scss';
import { Button } from '../Button/Button';
import { EmailModal } from '../EmailModal/EmailModal';
import { securityAPI } from '../../api/security';

interface EmailFormProps {
  isBig?: boolean;
  className?: string;
  onBtn?: () => void;
}

export const EmailForm = ({ isBig, className, onBtn }: EmailFormProps) => {
  const [inputText, setInputText] = useState('');
  const [modal, setModal] = useState(false);
  const [success, setSuccess] = useState(false);
  const handleClick = () => {
    onBtn?.();
    setModal(true);
    (async () => {
      const accessToken = window.localStorage.getItem('access_token');
      if (accessToken) {
        const res = await securityAPI.addEmail(inputText, accessToken);
        setSuccess(true);
        setInputText('');
      } else {
        setSuccess(false);
      }
    })();
  };
  return (
    <div className={classNames('email-form', className, { 'email-form_big': isBig })}>
      <input
        className='email-form__input'
        value={inputText}
        onChange={(e) => setInputText(e.target.value)}
        type='email'
        placeholder='Enter your email address'
      />
      {/* <div className='email-form__button'>send</div> */}
      <Button bg='gradient' innerClassName='email-form__button' onClick={handleClick}>
        Submit
      </Button>
    </div>
  );
};
