import { atom } from 'recoil';

// export interface openedRowStateType {
//   isOpened: boolean;
// }

export const openedRowState = atom({
  key: 'openedRowState',
  default: '',
});
