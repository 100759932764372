import React, { useEffect, useState } from 'react';
import './History.scss';
import { ReactComponent as EthIcon } from '../../icons/ethereum.svg';
import cn from 'classnames';
import { useRecoilState, useRecoilValue } from 'recoil';
import { userState } from '../../states/userState';
import { assetsAPI } from '../../api/assets';
import { historyState } from '../../states/historyState';
// import { tokens } from '../../constants/tokens';

interface HistoryProps {
  isVisible: boolean;
}
interface HistoryItem {
  date: string;
  asset_tag: string;
  type: string;
  usdt: number;
  amount: string;
  price: string;
}

export const History = ({ isVisible }: HistoryProps) => {
  const user = useRecoilValue(userState);
  const [history, setHistory] = useRecoilState(historyState);
  useEffect(() => {
    if (user) {
      (async () => {
        const { data } = await assetsAPI.getHistory(user.ethAddress);
        setHistory(data);
      })();
    }
  }, [user, setHistory]);
  if (!user) return null;
  return (
    <div className={cn('history', { history_visible: isVisible })}>
      {history
        .map((item: HistoryItem) => {
          const date = new Date(item.date).toLocaleDateString();
          // const symbol = tokens.find((x) => x.symbol === item.currency);
          return (
            <div key={item.date} className='history__item'>
              <div className='history__left'>
                {/* {symbol && symbol.icon} */}
                <div>{date}</div>
                <div className='history__currency'>{item.asset_tag.toUpperCase()}</div>
                {item.type}: {item.price} USDT
              </div>
              <div className='history__right'>
                {item.type === 'open long position' || item.type === 'open short position' ? (
                  <p className='history__price history__price_buy'>-{Number(item.usdt).toFixed(4)} USDT</p>
                ) : (
                  <p className='history__price history__price_sell'>+{Number(item.usdt).toFixed(4)} USDT</p>
                )}
                {/* {item.price && <p className='history__date text_13'>{item.price}</p>} */}

                {/* <p className='history__date text_13'>{date}</p> */}
              </div>
            </div>
          );
        })
        .reverse()}
      {history.length === 0 && (
        <div className='main-info__no-positions'>
          <h2>Make at least one trade to see a history</h2>
        </div>
      )}
    </div>
  );
};
