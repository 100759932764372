import React from 'react';
import './Share.scss';
import { Socials } from '../../../components/Socials/Socials';
import { ReactComponent as ArrowSvg } from '../../../icons/back-arrow.svg';

export const Share = () => {
  return (
    <div className='share'>
      <div className='container'>
        {/* <img className='share__pattern' src='/images/bg-share-pattern.png' alt='background pattern' /> */}

        {/*<div className='share__block'>
          <p className='share__title'>
            Share about the service and get <span className='share__amount'>$100,000</span> to your virtual
            balance
          </p>
          <Socials />

          <ArrowSvg className='share__arrow share__arrow_1' />
          <ArrowSvg className='share__arrow share__arrow_2' />
          <ArrowSvg className='share__arrow share__arrow_3' />
        </div>
        */}
      </div>
    </div>
  );
};
