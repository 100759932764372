import React, { useEffect, useMemo, useState } from 'react';
import TradingViewWidget from '../ConstructorChart/TradingViewWidget';
import cn from 'classnames';
import { SortArrows } from '../../../components/SortArrows/SortArrows';
import { ReactComponent as ArrowSvg } from '../../../icons/arrow.svg';
import { useRecoilState, useRecoilValue } from 'recoil';
import { AssetItem, userState } from '../../../states/userState';
import Skeleton from '../../../components/Skeleton/Skeleton';
import './AssetRow.scss';
import classNames from 'classnames';
import { openedRowState } from '../../../states/openedRowState';

interface AssetRowProps {
  currentToken: AssetItem;
  setChildrenArray: any;
  childrenArray: any;
}

function formatPrice(price: number) {
  return price;
  // let fixedValue = 5;
  // while (fixedValue < 100 && price.toFixed(fixedValue).slice(-1) === '0') {
  //   fixedValue++;
  // }
  // let roundedPrice = price.toFixed(Math.min(fixedValue, 100));
  // if (roundedPrice.slice(-1) === '5') {
  //   const nextDigit = +roundedPrice.charAt(roundedPrice.length - 2) + 1;
  //   roundedPrice = roundedPrice.slice(0, -2) + nextDigit;
  // }
  // return +roundedPrice;
}

export const AssetRow = ({ currentToken, setChildrenArray, childrenArray }: AssetRowProps) => {
  const [openedRow, setOpenedRow] = useRecoilState<string>(openedRowState);

  const user = useRecoilValue(userState);
  const [isOpenWidget, setIsOpenWidget] = useState(false);
  const [last24Change, setLast24Change] = useState('0.00');
  const [price, setPrice] = useState<any>('0');
  const [amountPrice, setAmountPrice] = useState(0);

  useEffect(() => {
    if (openedRow !== currentToken.symbol) {
      setIsOpenWidget(false);
    }
  }, [openedRow, currentToken]);

  useEffect(() => {
    setPrice('0');
    setLast24Change('0.00');
    setAmountPrice(0);

    fetch(`https://api.binance.com/api/v1/ticker/24hr?symbol=${currentToken.symbol.toUpperCase()}USDT`)
      .then((response) => response.json())
      .then((data) => {
        setPrice(Number(data.lastPrice));
        setLast24Change(data.priceChangePercent);
        setChildrenArray((childrenArray: any) => [
          ...childrenArray,
          [data.priceChangePercent, currentToken.symbol],
        ]);
        setAmountPrice(Number(currentToken.amount) * data.lastPrice);
      });

    const interval = setInterval(() => {
      fetch(`https://api.binance.com/api/v1/ticker/24hr?symbol=${currentToken.symbol.toUpperCase()}USDT`)
        .then((response) => response.json())
        .then((data) => {
          setPrice(Number(data.lastPrice));
          setLast24Change(data.priceChangePercent);
          setAmountPrice(Number(currentToken.amount) * data.lastPrice);
        });
    }, 15000);

    return () => {
      clearInterval(interval);
    };
  }, [currentToken, setChildrenArray]);

  const lastDayResult = useMemo(() => {
    const parsedLast24Change = Number(last24Change).toFixed(2);

    return Number(last24Change) > 0 ? +parsedLast24Change : parsedLast24Change;
  }, [last24Change]);

  const amount = useMemo(() => {
    if (!user) return '';
    const userAmount = Number(currentToken.amount);
    return userAmount > 0 && userAmount;
  }, [user, currentToken]);

  if (!price) return null;

  return (
    <div className={cn('asset-list__row text_16', { 'asset-list__row_active': isOpenWidget })}>
      <div
        onClick={() => {
          setIsOpenWidget(!isOpenWidget);
          setOpenedRow(currentToken.symbol);
        }}
        className={classNames('asset-list__row-info', { 'asset-list__row-info_opened': isOpenWidget })}
      >
        <div className='asset-list__name'>
          <div className='asset-list__name-flex'>
            <img className='asset-list__icon' src={`cryptoicons/${currentToken.symbol}.png`} alt='' />
            <span className='asset-list__token-name'>{currentToken.symbol}</span>
          </div>

          <div
            className={classNames('asset-list__last', 'asset-list__name-price', {
              'asset-list__last_green': Number(last24Change) > 0,
              'asset-list__last_red': Number(last24Change) < 0,
            })}
          >
            {last24Change === '0.00' ? <Skeleton width={70} height={30} /> : `${lastDayResult}%`}
          </div>
        </div>
        <div className='asset-list__amount'>
          {Number(amount) > 0 && (
            <>
              <div className='asset-list__value-label'>Amount</div>
              {amount}
            </>
          )}
        </div>
        <div className='asset-list__amount asset-list__last_hide'>
          {Number(currentToken.amount) > 0 ? (
            <>
              <div className='asset-list__value-label'>Amount (USDT)</div>
              {amountPrice.toFixed(2)}
            </>
          ) : (
            ''
          )}
        </div>
        <div className='asset-list__price'>
          <div className='asset-list__value-label'>Price</div>
          {price === '0' ? <Skeleton width={70} height={30} /> : `$${price}`}
        </div>
        <div
          className={classNames('asset-list__last', 'asset-list__last_hide', {
            'asset-list__last_green': Number(last24Change) > 0,
            'asset-list__last_red': Number(last24Change) < 0,
          })}
        >
          <div className='asset-list__value-label'>Last</div>
          {last24Change === '0.00' ? <Skeleton width={70} height={30} /> : `${lastDayResult}%`}
        </div>
        <div className={classNames('asset-list__arrow', { 'asset-list__arrow_opened': isOpenWidget })}>
          <ArrowSvg />
        </div>
      </div>
      <div style={{ height: isOpenWidget ? 'fit-content' : 0 }} className='asset-list__chart-wrap'>
        <TradingViewWidget isOpen={isOpenWidget} currentToken={currentToken} currentPrice={price} />
      </div>
    </div>
  );
};
