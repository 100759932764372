import React from 'react';
import { ReactComponent as Logo } from '../../../icons/logo.svg';
import { EmailForm } from '../../EmailForm/EmailForm';
import { Link } from 'react-router-dom';

import './Footer.scss';

export const Footer = () => {
  const scrollToBlock = (id: string) => {
    setTimeout(() => {
      const element = document.getElementById(id);
      element.scrollIntoView({ behavior: 'smooth' });
    }, 500);
  };
  return (
    <div className='footer'>
      <div className='footer__container container'>
        <div className='footer__grid'>
          <div className='footer__first'>
            <div className='footer__logo'>
              <div>
                <Logo />
                <p className='footer__logo-text'>Your best crypto partner</p>
              </div>

              {/* <div className='footer__socials'> */}
              {/*   <a className='footer__link' href='/'> */}
              {/*     <img src='/images/linkedin.svg' alt='social' /> */}
              {/*   </a> */}
              {/*   <a className='footer__link' href='/'> */}
              {/*     <img src='/images/facebook-dark.svg' alt='social' /> */}
              {/*   </a> */}
              {/*   <a className='footer__link' href='/'> */}
              {/*     <img src='/images/instagram.svg' alt='social' /> */}
              {/*   </a> */}
              {/* </div> */}
            </div>
            {/* <div className="footer__links">
              <p className="footer__links-title"></p>
            </div> */}
          </div>
          <div className='footer__links'>
            <p className='footer__subtitle'>Quick Links</p>
            <ul className='footer__list'>
              {/* <li className='footer__list-item'> */}
              {/*   <Link to='/#' className='footer__list-item-link'> */}
              {/*     About us */}
              {/*   </Link> */}
              {/* </li> */}
              <li className='footer__list-item'>
                <Link
                  to='/#how-it-works'
                  onClick={() => scrollToBlock('how-it-works')}
                  preventScrollReset={true}
                  className='footer__list-item-link'
                >
                  How DemoTrade works
                </Link>
              </li>
              {/* <li className='footer__list-item'> */}
              {/*   <Link to='#' className='footer__list-item-link'> */}
              {/*     Referral program */}
              {/*   </Link> */}
              {/* </li> */}
              {/* <li className='footer__list-item'> */}
              {/*   <Link to='#' className='footer__list-item-link'> */}
              {/*     Blog */}
              {/*   </Link> */}
              {/* </li> */}
            </ul>
          </div>
          <div className='footer__form'>
            <p className='footer__subtitle'>Submit for updates</p>
            <p className='footer__text'>Subscribe to get updates and news to empower your trading skills</p>
            <EmailForm className='footer__email' />
            <p className='footer__email-desc'>
              By completing this form, you acknowledge and agree to our privacy policy
            </p>
          </div>
        </div>
        <div className='footer__extra'>
          <p className='footer__text'>DemoTrade ©. All rights reserved.</p>
        </div>
      </div>
    </div>
  );
};
