import { createContext } from 'react';

export type TAddEmailContext = {
  showModal: boolean;
  setShowModal: (e: boolean) => void;
};

export const AddEmailContext = createContext<TAddEmailContext>({
  showModal: false,
  setShowModal: (e) => null,
});
