import React, { useState } from 'react';
import { ReactPortal } from '../ReactPortal/ReactPortal';
import { ReactComponent as CloseIcon } from '../../icons/close.svg';
import { EmailForm } from './EmailForm';
import './EmailForm.scss';

import { useMediaQuery } from '../../hooks/useMatchMedia';
import { EmailModal } from '../EmailModal/EmailModal';
import { securityAPI } from '../../api/security';
import classNames from 'classnames';
import { Button } from '../Button/Button';

export type TAddEmailModal = {
  handleClose?: () => void;
  isOpen: boolean;
};

export const AddEmailModal = ({ handleClose, isOpen }: TAddEmailModal) => {
  const isDesktop = useMediaQuery('(max-width: 996px)');
  const [modal, setModal] = useState(false);
  const [success, setSuccess] = useState(false);
  const [inputText, setInputText] = useState('');
  if (!isOpen) return null;

  const handleClick = () => {
    setModal(true);
    (async () => {
      const accessToken = window.localStorage.getItem('access_token');
      if (accessToken) {
        const res = await securityAPI.addEmail(inputText, accessToken);
        setSuccess(true);
        setInputText('');
      } else {
        setSuccess(false);
      }
    })();
    handleClose();
  };

  return (
    <ReactPortal wrapperId='add-email-modal-wrapper'>
      <div className='add-email-modal-wrapper__bg' onClick={handleClose} />
      <div className='content-wrapper'>
        <div className='content-wrapper__close-btn'>
          <CloseIcon onClick={handleClose} />
        </div>
        <div className='content-wrapper__body'>
          <div className='container'>
            <div className='email-block__content'>
              <p className='email-block__subtitle'>
                {isDesktop
                  ? 'Ready to start trading cryptocurrency?'
                  : 'Ready to kickstart your cryptocurrency trading journey?'}
              </p>
              <p className='email-block__title'>provide your email address</p>
              <form className={classNames('email-form', 'email-form_big')}>
                <input
                  className='email-form__input'
                  value={inputText}
                  onChange={(e) => setInputText(e.target.value)}
                  type='email'
                  placeholder='Enter your email address'
                />
                {/* <div className='email-form__button'>send</div> */}
                <Button bg='gradient' innerClassName='email-form__button' onClick={handleClick}>
                  Send
                </Button>
              </form>
              <p className='email-block__desc'>
                By completing this form, you acknowledge and agree to our privacy policy
              </p>
            </div>
          </div>
        </div>
      </div>
    </ReactPortal>
  );
};
