import { atom } from 'recoil';

interface Asset {
  type: 'long' | 'short';
  amount: number;
  price: number;
  usdt: number;
}

export interface AssetFileds {
  tag: string;
  name: string;
  type: string;
  amount: string;
  price: string;
  usdt: string;
  symbol: string;
  color: string;
}

export type AssetItem = AssetFileds;

export interface UserType {
  _id?: string;
  symbol?: string;
  color?: string;
  create_at: string;
  ethAddress: string;
  usdt: number;
  btc: Asset;
  eth: Asset;
  bnb: Asset;
}

export const userState = atom<any | null>({
  key: 'userState',
  default: null,
});
