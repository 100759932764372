import { useRecoilValue } from 'recoil';
import { userState } from '../states/userState';
import { userAPI } from '../api/user';
import { useUpdatePositions } from './useUpdatePositions';

export const useCheckPositions = (currency: string) => {
  const user = useRecoilValue(userState);
  const updatePositions = useUpdatePositions();
  const checkPositions = async () => {
    userAPI
      .check({ address: user.ethAddress, currency })
      .then(() => updatePositions())
      .catch(() => false);
  };
  return checkPositions;
};
