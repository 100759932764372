import React, {
  ChangeEvent,
  FocusEvent,
  Reducer,
  SyntheticEvent,
  useCallback,
  useEffect,
  useReducer,
  useRef,
  useState,
} from 'react';
import { toast } from 'react-toastify';
import { useRecoilState } from 'recoil';
import { userAPI } from '../../../../api/user';
import { userState } from '../../../../states/userState';
import { getBuyCountValue, getTotalPrice } from '../../../../functions/getTotalPrice';
import cn from 'classnames';
import './Terminal.scss';

interface TerminalProps {
  currentToken: any;
  currentPrice: string;
}

type TLimit = {
  min: string;
  max: string;
};

const initialInputValue = '1';

export const Terminal = ({ currentPrice, currentToken }: TerminalProps) => {
  const [user, setUser] = useRecoilState(userState);
  const [buyCountValue, setBuyCountValue] = useState(initialInputValue);
  const [totalValue, setTotalValue] = useState(Number(currentPrice).toFixed(6));
  const [maxInputValue, setMaxInputValue] = useState(user.usdt / Number(currentPrice));
  const [accordionCheckbox, setAccordionCheckbox] = useState(false);
  const [isHasOpenedPosition, setIsHasOpenedPosition] = useState();
  const [limit, setLimit] = useReducer<Reducer<TLimit, Partial<TLimit>>>(
    (prev, next) => {
      return { ...prev, ...next };
    },
    { max: currentToken.take_profit, min: currentToken.stop_loss },
  );
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const maxTokens = user.usdt / Number(currentPrice);

    setMaxInputValue(maxTokens);
    setIsHasOpenedPosition(currentToken.type);
  }, [currentPrice, user, currentToken]);
  useEffect(() => {
    if (Number(totalValue) === 0) {
      setTotalValue(getTotalPrice(buyCountValue, currentPrice).toFixed(6));
    }
  }, [currentPrice, buyCountValue, totalValue]);
  useEffect(() => {
    const target = inputRef.current;
    const min = Number(target.min);
    const max = Number(target.max);
    const val = Number(target.value);
    const percentage = ((val - min) * 100) / (max - min);
    target.style.backgroundSize = percentage + '% 100%';
  }, [buyCountValue, totalValue, maxInputValue]);
  useEffect(() => {
    if (Number(totalValue) <= 1 && currentPrice !== '0') {
      const l = 1 / Number(currentPrice);
      if (l <= 1) {
        setBuyCountValue(l.toFixed(6).toString());
      } else {
        setBuyCountValue(getBuyCountValue('1', currentPrice).toString());
      }
      setTotalValue('1');
    }
  }, []);

  useEffect(() => {
    if (isHasOpenedPosition) {
      setBuyCountValue(currentToken.amount.toString());
      setTotalValue(getTotalPrice(currentToken.amount.toString(), currentPrice).toString());
    }
  }, [isHasOpenedPosition, currentToken, currentPrice]);

  // if (!address || !user) return null;

  // const asset: (string | number)[] = Object.values(user[currentToken.symbol]);
  // const asset: any = currentToken.symbol;

  // const isHasOpenedPosition = asset[2] > 0;

  // console.log(currentPrice);

  const buy = () => {
    if (
      (Number(limit.max) < Number(currentPrice) || Number(limit.min) > Number(currentPrice)) &&
      (Number(limit.max) !== 0 || Number(limit.min) !== 0)
    ) {
      toast.error('wrong limit, please change it');
      return;
    }
    userAPI
      .buyToken({
        address: user.ethAddress,
        currentPrice,
        buyCountValue,
        symbol: currentToken.symbol,
        stopLoss: limit.min,
        takeProfit: limit.max,
      })
      .then((res) => {
        if (res.data.message === 'Succes') {
          userAPI
            .getUser(user.ethAddress)
            .then((res) => {
              setUser(res.data);
            })
            .catch((error) => console.log(error));
          toast.success(
            `You have successfully bought ${buyCountValue} ${currentToken.symbol} for ${totalValue} USDT`,
          );
        }
      })
      .catch((e) => toast.error(e.response.data.message));
  };
  const sell = () => {
    if (
      (Number(limit.max) > Number(currentPrice) || Number(limit.min) < Number(currentPrice)) &&
      (Number(limit.max) !== 0 || Number(limit.min) !== 0)
    ) {
      toast.error('wrong limit, please change it');
      return;
    }
    userAPI
      .sellToken({
        address: user.ethAddress,
        currentPrice,
        sellCountValue: buyCountValue,
        symbol: currentToken.symbol,
        takeProfit: limit.min,
        stopLoss: limit.max,
      })
      .then((res) => {
        if (res.data.message === 'Succes') {
          userAPI
            .getUser(user.ethAddress)
            .then((res) => {
              setUser(res.data);
            })
            .catch((error) => console.log(error));
          toast.success(
            `You have successfully sold ${buyCountValue} ${currentToken.symbol} for ${totalValue} USDT`,
          );
        } else {
          toast.error('Error. Try again');
        }
      })
      .catch((e) => toast.error(e.response.data.message));
  };
  const closePosition = useCallback(async () => {
    userAPI.closePosition(user.ethAddress, currentToken.asset.symbol, currentToken.id).then((res) => {
      if (res.data.message === 'Success') {
        userAPI
          .getUser(user.ethAddress)
          .then((res) => {
            setUser(res.data);
          })
          .catch((error) => console.log(error));
        toast.success(
          `You have successfully sold ${buyCountValue} ${currentToken.symbol} for ${totalValue} USDT`,
        );
      } else {
        toast.error('Error. Try again');
      }
    });
  }, []);

  const handleInput = (e: ChangeEvent<HTMLInputElement>) => {
    if (isHasOpenedPosition) return;
    const { target } = e;
    setBuyCountValue(target.value);
    setTotalValue(getTotalPrice(target.value, currentPrice).toString());
  };

  const totalValueHandler = (e: ChangeEvent<HTMLInputElement>) => {
    if (isHasOpenedPosition) return;
    setTotalValue((val) => {
      if (Number.isNaN(Number(e.target.value))) return val;
      const maxPrice = getTotalPrice(maxInputValue.toString(), currentPrice);
      if (Number(e.target.value) > maxPrice) {
        setBuyCountValue(maxInputValue.toFixed(2));
        return maxPrice.toFixed(2);
      }
      const l = Number(e.target.value) / Number(currentPrice);
      if (l <= 1) {
        setBuyCountValue(l.toFixed(6).toString());
      } else {
        setBuyCountValue(getBuyCountValue(e.target.value, currentPrice).toString());
      }
      return e.target.value;
    });
  };

  const conuntValueHandler = (e: ChangeEvent<HTMLInputElement>) => {
    if (isHasOpenedPosition) return;
    setBuyCountValue((val) => {
      if (Number.isNaN(Number(e.target.value))) return val;
      if (Number(e.target.value) > maxInputValue) {
        setTotalValue(getTotalPrice(maxInputValue.toFixed(2), currentPrice).toString());
        return maxInputValue.toFixed(2);
      }
      setTotalValue(getTotalPrice(e.target.value, currentPrice).toString());
      return e.target.value;
    });
  };

  const onBlur = (event: SyntheticEvent) => {
    if (Number((event.target as HTMLInputElement).value) < 0.001) {
      setBuyCountValue(initialInputValue);
      setTotalValue(getTotalPrice(initialInputValue, currentPrice).toString());
    }
  };

  const onTotalFieldBlur = (e: FocusEvent<HTMLInputElement, Element>) => {
    if (Number(e.target.value) <= 0) {
      if (1 / Number(currentPrice) <= 1) {
        setBuyCountValue((1 / Number(currentPrice)).toFixed(6).toString());
      } else {
        setBuyCountValue(getBuyCountValue('1', currentPrice).toString());
      }
      setTotalValue('1');
    }
  };
  // useEffect(() => {
  //   if (
  //     isHasOpenedPosition &&
  //     (currentToken?.stop_loss || currentToken?.take_profit) &&
  //     (Number(currentPrice) < Number(currentToken.take_profit) ||
  //       Number(currentPrice) < Number(currentToken.stop_loss))
  //   ) {
  //     closePosition();
  //   }
  // }, [currentToken, currentPrice, isHasOpenedPosition, closePosition]);

  return (
    <div className={'widget__terminal terminal'}>
      <div className={'terminal__trade-box'}>
        <h2 className={'terminal__price'}>Balance ${user.usdt.toFixed(6)}</h2>
        <div className={'terminal__inputs'}>
          <div className='terminal__range'>
            <input
              ref={inputRef}
              type='range'
              min='0.001'
              max={maxInputValue}
              value={Number(buyCountValue)}
              onChange={handleInput}
              step='0.001'
            />
          </div>
          <div className={'terminal__label'}>
            <div className={'terminal__placeholder text_13'}>Count</div>
            <input
              type='number'
              className={'terminal__input text_16'}
              value={buyCountValue}
              onChange={conuntValueHandler}
              onBlur={(event) => onBlur(event)}
            />
            {/* <input
              className={'terminal__input terminal__input_absolute text_16 radius_12'}
              value={buyCountValue + ` ${currentToken.symbol.toUpperCase()}`}
              readOnly={true}
              type='text'
            /> */}
            <div className='terminal__symbol-title'>{currentToken.symbol.toUpperCase()}</div>
          </div>
          <div className={'terminal__label'}>
            <div className={'terminal__placeholder text_13'}>Total</div>
            <input
              type='number'
              className={'terminal__input text_16 radius_12'}
              // readOnly={true}
              onChange={totalValueHandler}
              onBlur={(e) => onTotalFieldBlur(e)}
              value={totalValue}
            />
            <div className='terminal__symbol-title'>USDT</div>
          </div>
          <div className='accordion'>
            <div className='accordion__header' onClick={() => setAccordionCheckbox(!accordionCheckbox)}>
              <p>Take Profit / Stop Loss</p>
              <input type='checkbox' className='accordion__check' checked={accordionCheckbox} />
            </div>
            <div className={`accordion__body accordion__body${accordionCheckbox ? '_open' : '_close'}`}>
              <div className={'terminal__label'}>
                <div className={'terminal__placeholder text_13'}>Take Profit</div>
                <input
                  type='text'
                  className={'terminal__input text_16 radius_12'}
                  // readOnly={true}
                  onChange={(e) => {
                    if (Number.isNaN(Number(e.target.value)) || isHasOpenedPosition) return;
                    setLimit({ max: e.target.value });
                  }}
                  value={limit.max}
                />
                <div className='terminal__symbol-title'>USDT</div>
              </div>
              <div className={'terminal__label'}>
                <div className={'terminal__placeholder text_13'}>Stop Loss</div>
                <input
                  type='text'
                  className={'terminal__input text_16 radius_12'}
                  // readOnly={true}
                  onChange={(e) => {
                    if (Number.isNaN(Number(e.target.value)) || isHasOpenedPosition) return;
                    setLimit({ min: e.target.value });
                  }}
                  value={limit.min}
                />
                <div className='terminal__symbol-title'>USDT</div>
              </div>
            </div>
          </div>
          {!isHasOpenedPosition && (
            <div className={'terminal__buttons'}>
              <div
                onClick={() => !isHasOpenedPosition && buy()}
                className={cn('terminal__button terminal__button_green radius_12', {
                  terminal__button_disabled: isHasOpenedPosition || !currentPrice,
                })}
              >
                {user?.usdt < +totalValue ? 'Not enough money' : 'Buy/Long'}
              </div>
              <div
                onClick={() => !isHasOpenedPosition && sell()}
                className={cn('terminal__button terminal__button_red radius_12', {
                  terminal__button_disabled: isHasOpenedPosition || !currentPrice,
                })}
              >
                {user?.usdt < +totalValue ? 'Not enough money' : 'Sell/Short'}
              </div>
            </div>
          )}
          {isHasOpenedPosition && (
            <div
              onClick={closePosition}
              className={cn('terminal-position__button text_16', {
                terminal__button_disabled: !currentPrice,
              })}
            >
              Close position
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
