import React from 'react';
import ContentLoader from 'react-content-loader';

interface SkeletonProps {
  width?: number;
  height?: number;
}

const Skeleton = ({ width, height }: SkeletonProps) => {
  const viewBox = `0 0 ${width} ${height}`;

  return (
    <ContentLoader
      speed={2}
      width={width}
      height={height}
      viewBox={viewBox}
      backgroundColor='#393D49'
      foregroundColor='#53596a'
    >
      <rect x='0' y='0' rx='5' ry='5' width={width} height={height} />
    </ContentLoader>
  );
};

export default Skeleton;
