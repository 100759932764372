import React from 'react';
import { Button } from '../../../components/Button/Button';
import './FirstScreen.scss';
import { MetamaskButton } from '../../../components/MetamaskButton/MetamaskButton';
import { useRecoilState } from 'recoil';
import { userState } from '../../../states/userState';
import { Link } from 'react-router-dom';

export const FirstScreen = () => {
  const [user, setUser] = useRecoilState(userState);

  return (
    <section className='first-screen'>
      <picture>
        <source srcSet='/images/bg-firstscreen-pattern-tab.png' media='(max-width: 992px)' />
        <img
          className='first-screen__back first-screen__pattern '
          src='/images/bg-firstscreen-pattern.png'
          alt='background pattern'
        />
      </picture>
      <picture>
        <source srcSet='/images/bg-first-screen-blur-tab.png' media='(max-width: 992px)' />
        <img
          className='first-screen__back first-screen__blur'
          src='/images/bg-first-screen-blur.png'
          alt='background pattern'
        />
      </picture>
      <picture>
        <source srcSet='/images/bg-first-screen-blur-2-tab.png' media='(max-width: 992px)' />
        <img
          className='first-screen__back first-screen__blur-2'
          src='/images/bg-first-screen-blur-2.png'
          alt='background pattern'
        />
      </picture>
      <div className='container first-screen__container'>
        <h1 className='first-screen__title'>take your crypto trading to the next level with DemoTrade</h1>
        <p className='first-screen__subtitle'>
          don’t lose money while training – practice trading virtual assets in real market conditions
        </p>

        {!user ? (
          <div
            style={{
              marginTop: 20,
            }}
          >
            <MetamaskButton className='first-screen__btn button_medium' isBig isDefaultButton>
              Start Now
            </MetamaskButton>
          </div>
        ) : (
          <Link
            style={{
              marginTop: 20,
            }}
            to={'/dashboard'}
          >
            <Button bg='gradient' className='first-screen__btn' isBig>
              Get Started Now
            </Button>
          </Link>
        )}
        <img src='/images/Mockup.png' alt='img section' className='first-screen__image' />
      </div>
    </section>
  );
};
