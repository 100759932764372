export const faqData = [
  {
    title: 'Are there trading limits?',
    text: 'No, but don`t spend your entire balance at once, trade responsibly',
  },
  {
    title: 'Can I influence the real market?',
    text: 'No, this is a demo trading, you will not be able to influence the real price in any way by buying or selling 1 million USDT',
  },
  {
    title: 'Why do I need DemoTrade?',
    text: 'Everyone wants to learn how to trade profitably. We give this opportunity',
  },
  {
    title: 'How do I start trading?',
    text: 'Click on any cryptocurrency, a chart will open for you, select how much you want to buy to the right of the chart, click Buy. To sell your asset, follow the same steps and click Sell',
  },
  {
    title: 'How many cryptocurrencies are available?',
    text: '100 most popular',
  },
  {
    title: 'What trading pairs are available?',
    text: 'Only cryptocurrency pairs to USDT are available',
  },
  {
    title: 'What will I get?',
    text: 'Experience and basic knowledge on how to trade on demo account. After that you can use your skills on real account and make profit.',
  },
  {
    title: 'How do I get a new balance?',
    text: 'Trade responsibly, do not make large purchases, diversify risks. The balance is issued only once per account.',
  },
  {
    title: 'Are the prices real?',
    text: 'Yes, the price in the list is updated every 15 seconds, the price on the chart is updated every second. ',
  },
];
